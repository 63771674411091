<template>
  <main id="GetStart">
    <div class="row main">
      <InfoType v-show="info_type == -1" v-model="info_type" />
    </div>
    <Footer />
  </main>
</template>

<script>
import SmoothScrollResister from '@/common/GSAP/SmoothScrollResister.js';
import Footer from '@/components/MainFooter';
import InfoType from '@/components/GetStart/InfoType';
import { GetMetaData } from '@/common/meta.js';
export default {
  name: 'GetStart',
  mixins: [SmoothScrollResister],
  components: {
    Footer,
    InfoType,
  },
  data() {
    return {
      info_type: -1,
      meta_data: null,
    };
  },
  methods: {},
  mounted() {
    this.RegisterGsap('GetStart');
          window.prerenderReady = true;
  },
  created() {
    this.meta_data = GetMetaData('開始使用', '', '');
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>
