<template>
  <div id="InfoType">
    <div class="row">
      <h4 class="title">選擇資料填寫方式</h4>
      <div class="option_box">
        <button class="option" @click="$router.push('/easy_form')">
          <div class="content">
            <h4>上傳名片圖檔</h4>
            <p>
              您只需上傳名片的正反面圖檔、封面照片與Logo圖檔<br />我們將由專員協助幫您生成個人形象網站
            </p>
          </div>
        </button>
        <button class="option" @click="$router.push('/detail_form')">
          <div class="content">
            <h4>自行填寫完整資料</h4>
            <p>
              由您填寫完整的名片資訊<br />您可以自由編輯個人形象網站上的資訊與功能選用
            </p>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GetStartInfoType",
  data() {
    return {};
  },
  methods: {},
};
</script>